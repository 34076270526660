@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Bree Serif', serif;
}

@media (max-width: 768px) {
  html, body {
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
  }
}